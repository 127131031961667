<template>
  <div>
      <a-form
        v-if="details"
        class="need-box"
        :label-col="{ span: 7}"
        :wrapper-col="{ span: 15}"
        autocomplete="off"
        :scrollToFirstError='true'>
        <template v-if="noinfo>0">
          <a-form-item v-if="details.date" label="需求类型">
              {{details.date.name}}
          </a-form-item>
          <a-form-item label="需求模块">
            <a-breadcrumb separator=">">
              <a-breadcrumb-item v-for="(item,index) in details.modulars" :key="index">{{item}}</a-breadcrumb-item>
            </a-breadcrumb>
          </a-form-item>
          <a-form-item label="需求ID" v-if="noinfo!=3">{{details.id}}</a-form-item>
          <a-form-item v-if="noinfo==1&&authority.edit" label="状态">{{details.status_text}}</a-form-item>
          <a-form-item v-if="noinfo==1&&authority.edit" label="创建时间">{{details.createtime_text}}</a-form-item>
          <a-form-item v-if="noinfo==1&&authority.edit_hour&&details.date.name!='原始需求'" label="前端工时">
            <div class="houritem">
              <a-input v-model:value="newHour.front_hour" placeholder="输入前端工时" />
              <a-popconfirm
                title="确定修改此工时？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="editHour('front_hour')">
                <a-button class="btn clo1">修改</a-button>
              </a-popconfirm>


            </div>
          </a-form-item>
          <a-form-item v-if="noinfo==1&&authority.edit_hour&&details.date.name!='原始需求'" label="后端工时">
            <div class="houritem">
              <a-input v-model:value="newHour.back_hour" placeholder="输入后端工时" />
              <a-popconfirm
                title="确定修改此工时？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="editHour('back_hour')">
                <a-button class="btn clo1">修改</a-button>
              </a-popconfirm>
            </div>
          </a-form-item>

          <a-form-item v-if="noinfo==1&&authority.edit" label="已完成页面图">
              <template v-if="details.date&&details.date.name == '原始需求'">
              <div class="overimg-box">
                <p>静态</p>
                <div class="overimg" @click="openSwiper(1,details.complete_images.static)" v-if="details.complete_images.static.length > 0">
                  <a-image :preview="false" :width="65" :src="itm" v-for="(itm,ind) in details.complete_images.static" :key="ind"/>
                </div>
                <p class="overimg" v-else>暂无图片</p>
              </div>
              <div class="overimg-box" style="margin-top: 10px;">
                <p>联调</p>
                <div class="overimg" @click="openSwiper(2,details.complete_images.union)" v-if="details.complete_images.union.length > 0">
                  <a-image :preview="false" :width="65" :src="itm" v-for="(itm,ind) in details.complete_images.union" :key="ind"/>
                </div>
                <p class="overimg" v-else>暂无图片</p>
              </div>
            </template>
          </a-form-item>


          <a-form-item v-if="authority.addCases" label="相关用例">
            <div class="case-box">
              <a-tabs v-model:activeKey="casesTab">
                <a-tab-pane v-for="(i,index) in details.cases" :key="index" :tab="'用例 '+i.id">
                </a-tab-pane>
              </a-tabs>
              <div class="case-data um-scroll" v-if="details.cases&&details.cases[casesTab]">
                <h4 class="tit">用例摘要</h4>
                <p v-text="details.cases[casesTab].describe"></p>
                <h4 class="tit">前置条件</h4>
                <p v-text="details.cases[casesTab].preconditions"></p>
                <h4 class="tit">操作步骤</h4>
                <p v-for="txt in details.cases[casesTab].steps" v-text="txt.index+'. '+txt.content"></p>
                <h4 class="tit">预期结果</h4>
                <p v-text="details.cases[casesTab].results"></p>
              </div>
            </div>
          </a-form-item>
          <a-form-item v-if="noinfo!=3&&authority.createTask" label="相关任务">
            <a-table
              class="task-table"
              :columns="taskArr" 
              :data-source="details.tasks" 
              :scroll="{ y: 320 }" 
              bordered
              :pagination="false">
              <template #bodyCell="{ column ,record,index}">
                <template v-if="column.dataIndex === 'operation'">
                  <span v-if="!record.can_edit" class="span">修改</span>
                  <!-- <router-link v-else class="em" :to="'/taskProjectDetail?demandId='+details.id+'&id='+record.id"><span>修改</span></router-link> -->
				  <p class="em" v-else @click="updateBtn(record.id,record.describe,index)">修改</p>
                  <router-link class="em" :to="'/taskProjectDetail?id='+record.id"><span>详情</span></router-link>
                  
                </template>
              </template>
              </a-table>
          </a-form-item>

        </template>

        <template v-if="noinfo!=1">
          <a-form-item label="需求描述">
            <a-textarea v-if="onEdit" :style="{'height': (noinfo==0?220:noinfo==3?90:150) + 'px'}" v-model:value="onEdit.describe"/>
            <p v-else class="ant-input" :style="{'height': (noinfo==0?220:noinfo==3?90:150) + 'px'}" v-text="details.describe"></p>
          </a-form-item>
          <a-form-item label="参数/逻辑说明">
            <a-textarea v-if="onEdit" :style="{'height': (noinfo==0?120:noinfo==3?60:90) +'px'}" v-model:value="onEdit.explain" />
            <p v-else class="ant-input" :style="{'height': (noinfo==0?120:noinfo==3?60:90) +'px'}" v-text="details.explain"></p>
          </a-form-item>
          <a-form-item label="需求图片">
              <a-upload
                v-if="onEdit"
                name="file"
                v-model:file-list="onEdit.fileList"
                :maxCount="8"
                list-type="picture-card"
                :customRequest ="customRequest"
                @remove="remove">
                <div class="addimg" v-if="onEdit.fileList.length < 8">
                  <plus-outlined />
                  <p style="color: #999999;">点击上传图片</p>
                </div>
                </a-upload>
              <div v-else class="img-scroll" @click="openSwiper(0,details.imgArr)">
                <a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in details.imgArr" :key="ind"/>
              </div>


          </a-form-item>
          <a-form-item class="btns" v-if="noinfo!=3" :wrapper-col="{ offset:7}">
            <a-button class="btn" @click="goBack()">返回</a-button>
            <a-button 
				class="btn clo3" 
				v-if="authority.review&&details.status!= 'pass'" 
				@click="confirm('review')">通过</a-button>
            <a-button 
				class="btn clo2" 
				v-if="authority.fail&&(details.review_status=='wait'||details.review_status=='part')" 
				@click="setFailModal(details)">驳回</a-button>
			<a-button class="btn clo6" v-else>驳回</a-button>
            <a-button class="btn clo4" v-if="authority.submit&&details.status!='close'&&details.status!= 'pass'&&details.status!= 'reviewing'" @click="confirm('submit')">提交</a-button>
            <a-button class="btn clo5" v-if="authority.edit" @click="editDemand" >{{onEdit?'保存':'修改'}}</a-button>
            <a-button class="btn clo6" v-if="onEdit" @click="onEdit=!1" >取消</a-button>
            <a-button class="btn" v-if="authority.del" type="danger" @click="confirm('del')">删除</a-button>
            <a-button class="btn clo2" v-if="authority.close&&details.status!= 'close'" @click="confirm('close')">关闭</a-button>

            <!-- 项目 -->
              <router-link v-if="authority.createTask&&details.status!= 'pass'" :to="'/TaskCreate?demandId='+details.id"><a-button class="btn clo1">建任务</a-button></router-link>
            <!-- 项目 -->

            <!-- 测试 -->
              <router-link v-if="authority.addCases" :to="'/TheTestCaseAdd?demandId='+details.id"><a-button class="btn clo1">建用例</a-button></router-link>
            <!-- 测试 -->

          </a-form-item>
        </template>

      </a-form>
      <a-modal v-if="modals" v-model:visible="omodals" :title="modals.title" @ok="confirm(modals.fun,true)" @cancel="modals=null">
        <div v-text="modals.name"></div>
      </a-modal>
			<a-modal v-if="details.id==failModal" v-model:visible="failModal" title="驳回理由" @ok="failSubmit(failModal)">
				<a-form-item label="驳回理由">
					<a-input v-model:value="failText" placeholder="请输入驳回理由" />
				</a-form-item>
			</a-modal>
  </div>
</template>

<script>
export default {
  name: 'Demand',
  data(){
    return {
      modals: null,
      onEdit:!1,
      newHour:{
        front_hour:'',
        back_hour:''
      },
      casesTab:0,
      taskArr:[{
          title: '任务ID',
          width:70,
          dataIndex: 'id',
          fixed: 'left'
        }, {
          title: '任务描述',
          width: 120,
          dataIndex: 'describe'
        }, {
          title: '指派给',
          width: 90,
          dataIndex: 'user_nickname'
        }, {
          title: '操作',
          width: 100,
          dataIndex: 'operation',
          fixed: 'right'
      }],
	  describeIndex:0 ,// 任务下标
		failModal:null,
		failText:'',
    }
  },
  props:['details','noinfo'],
  computed: {
    user() {
      return this.$store.state.user
    },
    omodals(){
      return !!this.modals
    },
    authority(){
      var data = {};
      this.$store.state.params.forEach(item=>{
        let name = item.name.split('/');
        if(name[1]=='demand'){
          data[name[2]] = true;
        }else if(name[1]=='demand_date'){
          data[name[2]+'Date'] = true;
        }else if(name[1]=='cases'){
          data[name[2]+'Cases'] = true;
        }else if(name[1]=='task'){
          data['createTask'] = true;
        }
      })
      return data
    },
	isUpdateFresh(){
		return this.$store.state.isUpdateFresh
	},
	updateDescribe(){
		return this.$store.state.updateDescribe
	}
  },
  watch:{
    details(){
      this.newHour.front_hour = this.details.front_hour+'';
      this.newHour.back_hour = this.details.back_hour+'';
    },
	isUpdateFresh(val,oldvalue){
		// 修改完成后回显
		if(val){
			console.log("ddd", this.updateDescribe)
			this.details.tasks[this.describeIndex].describe = this.updateDescribe
			this.$store.commit('setUpdateId',null) // 重置Id
			this.$store.commit('setUpdateDescribe',"") //重置需求描述
			this.$store.commit('setIsTaskUpdate',0) // 任务修改标识
			this.$store.commit('setIsUpdateFresh',0) 
		}
	}
  },
  created(){
      this.newHour.front_hour = this.details.front_hour+'';
      this.newHour.back_hour = this.details.back_hour+'';
  },
  methods:{
    async editHour(name){
      if(!this.newHour[name]){
        return this.$utils.msgErr('请输入工时！')
      }
      var data = {
        ids:this.details.id,
        front_hour:this.details.front_hour+'',
        back_hour:this.details.back_hour+''
      }
      data[name] = this.newHour[name];

      let res = await this.$utils.api.post({
        url:"/demand/edit_hour",
        data:data,
        result:1
      })
      if(res.code==1){
        this.$utils.msgSuc('已修改')
        this.newHour.front_hour = data.front_hour+'';
        this.newHour.back_hour = data.back_hour+'';
      }

    },
    async confirm(name, jud){
        var type = {
          submit:'提交',
          del:'删除',
          close:'关闭',
          review:'审阅通过',
          fail:'审阅驳回',
        };
        if(!jud){
          this.modals = {
            title:'确认'+type[name]+'？',
            name:'是否确认'+type[name]+'此需求？',
            fun:name,
          };
          return
        }

        let res = await this.$utils.api.post({
            url:'/demand/'+name,
            data:{
              ids:this.details.id
            },
            result:1
          })
        this.modals = null;
        if(res.code == 1){
          this.$utils.msgSuc(type[name]+'成功')
          this.goBack()
        }
    },
    remove(e){
      this.onEdit.fileList = this.onEdit.fileList.filter((item) => {
        return item != e.thumbUrl
      })
    },
    async editDemand(){
      if(!this.onEdit){
		  let imgs =[]
		  if(this.details.imgArr){
			  imgs = this.details.imgArr.map((item,idx)=>{
			    return {
			      uid:idx+1,
			      name:item,
			      status: 'done',
			      url:item
			    }
			  })
		  }
        this.onEdit={
          describe:this.details.describe,
          explain:this.details.explain,
          fileList:imgs
        }
        return
      }
      if(this.onEdit.describe == ''){
        this.$utils.msgWar('请填写需求描述')
        return
      }
      if(this.onEdit.explain == ''){
        this.$utils.msgWar('请填写参数/逻辑说明')
        return
      }	
      let info = {
        ids: this.details.id,
        'row':{
          'describe': this.onEdit.describe,
          'explain': this.onEdit.explain,
          'images': this.onEdit.fileList.map(item=>item.url) +'',
        }
      }
      let res = await this.$utils.api.post({
        url:'/demand/edit',
        data:info,
        result:1
      })
      if(res.code == 1){
        this.$utils.msgSuc('修改成功')
        this.details.describe = this.onEdit.describe+'';
        this.details.explain = this.onEdit.explain+'';
        this.details.imgArr = this.onEdit.fileList.map(item=>item.url);
        this.onEdit = null;
      }

    },
    async customRequest (e) {
      // 执行图片上传
      let that = this
      let res = await this.$utils.api.upLoad(e)
      if(res){
        setTimeout(()=>{
          that.$utils.msgSuc('上传成功')
          // 上传成功修改文件状态
          that.onEdit.fileList[that.onEdit.fileList.length-1].status = 'done' 
          // 图片封面预览
          that.onEdit.fileList[that.onEdit.fileList.length-1].url = res.data.fullurl 
        },500)
      }
    },
    //onEdit=!0
    goBack(){
      // 返回
      this.$store.commit('setStore',{ name:'hideSider', data:0 })
      this.$store.commit('setStore',{ name:'history', data:null })
      this.$router.back()
    },
	updateBtn(id,describe,index){
		// 编辑
		this.describeIndex = index
		this.$store.commit('setIsRecordAdd',1) //弹出修改
		this.$store.commit('setUpdateId',id) // 修改Id
		this.$store.commit('setUpdateDescribe',describe) //回显
		this.$store.commit('setIsTaskUpdate',1) //任务
	},
	openSwiper(index,arr){
	  // 弹出图片列表
	  // let arr = index == 1 ? this.static : index == 2 ? this.union : this.details.imgArr
	  this.$store.commit('setSwiperArr',arr)
	  this.$store.commit('setSwiper',1)
	},
	setFailModal(record){
		if(record.review_status=='wait'||record.review_status=='part'){
	  		this.failModal = record.id;
		}
	},
	async failSubmit(){
		if(!this.failText){
			return this.$utils.msgErr('请输入驳回理由！')
		}
			let res = await this.$utils.api.post({
				url:"/demand/fail",
				data:{
					ids: this.failModal,
					msg: this.failText
				},
				result:1
			})
			if(res.code==1){
				this.$utils.msgSuc('已驳回')
				this.failModal = null;
				this.failText = '';
			}
	},
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.g-title{
  display: flex;
  padding-top:20px;
  padding-bottom:20px;

  .fx{
    flex:1;
  }
  .name{
    font-size:16px;
    line-height:30px;
    margin-right:20px;
    .tip{
      font-size:12px;
      opacity: .7;
      margin-left:20px;
    }
    &:before{
      content:'';
      display: inline-block;
      vertical-align: middle;
      border: 1px solid;
      width:2px;
      height:16px;
      position: relative;
      top:-2px;
      opacity: .6;
      margin-right:10px;
    }
  }
}
  .need-box{
    .houritem{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn{
        margin-left:20px;
      }
    }
    p.ant-input{
			word-break: break-all;
      border-color: transparent;
      background:#f5f5f5;
      border-radius: 5px;
    }
    textarea{
      resize:none;
    }
    .btns{
      justify-content: flex-start;
      padding-top:50px;
      .btn{
        margin-right: 20px;
      }
    }
    .case-box{
      .tit{
        font-size:14px;
        font-weight: bold;
        &:not(:first-child){
          padding-top:15px;
        }
      }
      .case-data{
        max-height:330px;
        p{
          margin-bottom:5px;
          font-size:14px;
          color:#777;
        }
      }
    }
    .task-table{
      .span{
        color:#999;
      }
      .em{
        color:#407cff;
        margin-left:10px;
      }
    }
  }
</style>
